<template>
   <v-list
      one-line
    >
      <v-list-item-group v-model="model">
           <template  v-for="(item, i) in langs" >
          <v-list-item
            :key="`item-${i}`"
            :value="item"
          >
            <template v-slot:default="{ active }">
              <v-list-item-content>
                <v-list-item-title class="text-body-large" v-text="item.title"></v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-checkbox
                  :input-value="active"
                  off-icon="$radioOff"
                  on-icon="$radioOn"
                ></v-checkbox>     
              </v-list-item-action>
            </template>
          </v-list-item>
           <v-divider
            :key="`divider-${i}`"
          ></v-divider>
           </template>
     
      </v-list-item-group>
    </v-list>
</template>

<script>
export default {
    data() {
        return {
            model: null,
            langs: [
                {title: 'ภาษาไทย (TH)' , locale: 'th'},
                {title: 'ภาษาอังกฤษ (EN)' , locale: 'en'}
            ]
        }
    },
}
</script>

<style>

</style>