<template>
 <v-app>
  <div class="fill-height">
    <SingleAppBar title="ภาษา" :backward="true"/>
    <v-main>
      <v-container fluid class="pa-0">
         <LangList/>
      </v-container>
   
    </v-main>
  </div>
 </v-app>
</template>

<script>
// @ is an alias to /src
import SingleAppBar from "@/components/common/SingleAppBar";
import LangList from "@/components/lang/LangList"
export default {
  name: "Home",
  components: {
    SingleAppBar,
    LangList
  },
};
</script>
